body,
body.light {
	--a-color: #ffffff;
	--b-color: #000000;
	--c-color: #568ea3;
	--d-color: #ba5c12;
	--e-color: #db9d47;

	--a-shadow-color: #cccccc;
	--c-shadow-color: #a1c2ce;

	--error-color: #df3e2f;
}

body.dark {
	--a-color: #000000;
	--b-color: #ffffff;

	--a-shadow-color: #333333;
	--c-shadow-color: #31525e;
}
