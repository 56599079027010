@import-normalize;

:root {
	color-scheme: light dark;
}

@import "./color-palette.scss";

* {
	box-sizing: border-box;
}

html,
body {
	width: 100vw;
	margin: 0;
	padding: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--a-color);
	color: var(--b-color);
	font-size: 10pt;
}

input,
button {
	font-size: inherit;
}

div#root {
	width: 100%;
	max-width: 400pt;
	margin: auto;
}
