input,
button {
	width: 100%;
	border: 1pt solid var(--c-color);
	border-radius: 15pt;
	padding: 0.8em;
	background-color: var(--a-color);
	color: inherit;
}

input:not([type="button"], [type="submit"]) {
	&:focus {
		outline: none !important;
		box-shadow: 0;
	}
}

label.float-label {
	position: relative;

	span {
		color: var(--a-shadow-color);
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10pt;
		left: 10pt;
	}

	input {
		&:not(:placeholder-shown) + span {
			display: none;
		}

		&:focus + span {
			display: none;
		}

		&::placeholder {
			color: var(--a-shadow-color);
			opacity: 0;
		}
	}
}

input:where([type="button"], [type="submit"]),
button {
	cursor: pointer;
	border-width: 2pt;
	&:active {
		border-top-width: 2.5pt;
		border-bottom-width: 1.5pt;
	}
}

input:where([type="radio"], [type="checkbox"]) {
	cursor: pointer;
	width: auto;
}
